.sidebar-list {
  .mat-mdc-list-item {
    height: 35px !important;
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    .mdc-list-item__content {
      padding: 0 8px !important;
    }

    &:hover {
      background: #e6f4f4;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
    &.mat-list-single-selected-option {
      background: #e6f4f4;

      &:focus {
        background: #e6f4f4;
      }
    }
  }
}
