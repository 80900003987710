@use '@angular/material' as mat;
table {
  background: transparent !important;
  border-collapse:separate !important;
  border-spacing: 0px 5px !important;

  tr.mat-mdc-header-row {
    height: 40px !important;
  }

  th.mat-mdc-header-cell {
    background: #e7f4f4 !important;
    color: #444 !important;
    border-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-right: 0;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      padding-left: 0px;
    }
  }

  $theme-test: mat.m2-get-color-from-palette($theme-primary);
  $borderColor: rgba($theme-test, .2);
  td.mat-mdc-cell {
    border-top: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor !important;
    padding-left: 10px;
    padding-right: 10px;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-left: 1px solid $borderColor;
      padding-right: 0;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid $borderColor;
      padding-left: 0px;
    }
  }
}

.mat-mdc-paginator {
  background: transparent !important;
}
