.mdc-snackbar__surface {
  background: #e6f4f4 !important;
  color: $dark-primary-text !important;

  &.error {
    background: #fff0f0 !important;
  }

  &.transparent {
    background: transparent !important;
    box-shadow: 0px !important;
  }

  .mdc-snackbar__label {
    color: #000000de !important;
  }
}


.mat-mdc-snack-bar-container.error .mdc-snackbar__surface {
  background: #ef9e8f !important;
}

