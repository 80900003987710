/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: ( display-4: mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em), display-3: mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em), display-2: mat.m2-define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em), display-1: mat.m2-define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em), headline: mat.m2-define-typography-level(28px, 34px, 500, 'Roboto', 0.0000em), title: mat.m2-define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em), subheading-2: mat.m2-define-typography-level(16px, 28px, 500, 'Roboto', 0.0094em), subheading-1: mat.m2-define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em), body-2: mat.m2-define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em), body-1: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em), button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em), caption: mat.m2-define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em), input: mat.m2-define-typography-level(14px, 1.125, 400, 'Roboto', 0px) );

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
$light-primary-text: white;

mat.$m2-light-theme-foreground-palette: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
)
;

// Background config
// Light bg
$light-background: #fcfcfc;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

mat.$m2-light-theme-background-palette: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
)
;

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-component-typographies($fontConfig);
@include mat.elevation-classes();
@include mat.app-background();

// Theme Config

$mat-primary: ( main: #106062, lighter: #117f81, darker: #0d4849, 100: #106062, 200: #106062, 300: #106062, 400: #117f81, 500: #117f81, 600: #117f81, 700: #0d4849, 800: #0d4849, 900: #0d4849, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

$mat-accent: ( main: #ff7300, lighter: #fd8420, darker: #d86405, 100: #ef9e8f, 200: #e21d1d, 300: #e97763, 400: #e4583f, 500: #df3d20, 600: #b33019, 700: #9c2a16, 800: #862413, 900: #862413,
contrast : ( main: $light-primary-text, lighter: $light-primary-text, darker: $light-primary-text, ) );
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

$mat-warn: ( main: #e4583f, lighter: #ffb3b3, darker: rgb(194, 56, 21), 100: #ef9e8f, 200: #e21d1d, 300: #e97763, 400: #e4583f, 500: #df3d20, 600: #b33019, 700: #9c2a16, 800: #862413, 900: #862413, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

/*$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);*/
$theme: mat.m2-define-light-theme(( color: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, ), typography: mat.m2-define-typography-config(), density: 0, ));

@include mat.all-component-themes($theme);

.sticky-footer-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background: #fcfcfc;

  .sticky-footer-page-content {
    flex: 1;
  }
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  color: mat.m2-get-color-from-palette($theme-primary) !important;
}

.error {
  color: mat.m2-get-color-from-palette($theme-warn) !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.create-space-between {
  flex: 1 1 auto;
}

hr {
  height: 1px;
  border: 0;
  background-color: rgba(0, 0, 0, 0.42);
}

.float-right {
  float: right;
}

p.accent, .accent-color {
  color: mat.m2-get-color-from-palette($theme-accent);
}

p.warn, .warn-color {
  color: mat.m2-get-color-from-palette($theme-warn);
}

.md-small {
  min-width: 40px;
  padding: 0 8px;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
.mat-tab-labels {
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
*/
  .mat-tab-label {
    min-width: auto;
    padding: 0px 8px;
  }
}



.mat-typography {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
  }
}

.mat-drawer-backdrop {
  opacity: 0 !important;
}

.mat-drawer-content, .mat-drawer-container {
  overflow: unset !important;
}

.mat-badge.mat-mdc-icon-button {
  .mat-badge-content {
    top: 15px !important;
    left: 35px !important;
  }
}


.mat-mdc-input-element {
  line-height: 1em;
}


.mat-mdc-card {
  box-shadow: 0 2px 1px -1px #0003,0 1px 1px #00000024,0 1px 3px #0000001f !important;
  padding: 16px;
}


mat-label {
  color: #111 !important;
  font-weight: 500 !important;
}

.flex-vertical-center {
  align-items: center;
  display: flex;
}

.position-sticky {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 85px;
  align-self: flex-start;
  // position: relative;
  z-index: 999999;
  background: #fcfcfc;
}

.text-center {
  text-align: center;
}

.mat-mdc-icon-button {
  &.small {
    width: 20px;
    height: 20px;
    line-height: 20px;
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
    /*.mdc-button__label {*/
      .mat-icon {
        line-height: 20px;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
        right:0px;
        bottom: 0px;
      }

      .material-icons {
        font-size: 20px;
      }
   /* }*/
  }

  &.x-small {
    width: 14px;
    height: 14px;
    line-height: 14px;
    /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
    /*.mdc-button__label {*/
      .mat-icon {
        line-height: 14px;
        height: 14px;
        width: 14px;
      }

      .material-icons {
        font-size: 14px;
      }
    /*}*/
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.
*/
.mat-progress-bar-fill::after {
  background: #91c3c4 !important;
}

.fa {
  &.primary {
    color: mat.m2-get-color-from-palette($theme-primary)
  }

  &.white {
    color: white;
  }

  &.f-20 {
    font-size: 20px !important;
  }
}

.max-height-select {
  max-height: 50vh !important;
}

.hide {
  display: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
mat-button-toggle-group {
  width: 100%;
  margin-bottom: 15px;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
mat-button-toggle {
  flex: 1 1 100%;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  display: flex;
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
  &.mat-button-toggle-checked {
    background: mat.m2-get-color-from-palette($theme-primary);
    color: white !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
  .mat-button-toggle-label-content {
    line-height: 30px !important;
  }
}

