
// Radio Controls
@use '@angular/material' as mat;
$radioSize: 15px;

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-outer-circle {
  width: $radioSize !important;
  height: $radioSize !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-inner-circle {
  width: $radioSize !important;
  height: $radioSize !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container  {
  width: $radioSize !important;
  height: $radioSize !important;
}

.mat-mdc-radio-button {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  &.mat-radio-ripple {
    width: $radioSize * 2 !important;
    height: $radioSize * 2 !important;
  }
}

// Checkbox Controls

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
  display: block !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  vertical-align: top !important;
  display: inline-block;
  margin-top: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-label {
  display: inline-block !important;
 /* width: calc(100% - 25px) !important;*/
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
mat-checkbox.transparent {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  &.mat-checkbox-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-background {
        background-color: transparent !important;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-checkmark {
          width: 80%;
          margin-left: 10%;
          margin-top: 1px;
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-checkbox-checkmark-path {
            stroke: mat.m2-get-color-from-palette($theme-primary) !important;
            stroke-width: 3px !important;
          }
        }
      }
    }
  }
}

// Material Form Fields

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-outline {
  background: white;
  border-radius: 4px;
}

.mat-form-field-invalid .mdc-notched-outline > * {
  border-color: #dc3545 !important;
}

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field-infix {
  padding: .7em .2em !important;
  min-height: auto !important;
}

.mat-mdc-form-field-icon-suffix{
    padding-right: 4px !important;
}

.mat-mdc-text-field-wrapper {
  background-color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-floating-label-wrapper {
  overflow: unset !important;
}

.mat-mdc-text-field-wrapper{
    padding-bottom: initial !important;;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-floating-label{
  //margin-top: -0.4em !important;
  font-size: 18px !important;
  color: #111 !important;
  font-weight: 500 !important;
}


// Select Controls

.mat-mdc-select-panel {
  padding-top: 10px;
  min-width: 100% !important;
  max-width: 100% !important;

  input {
    padding: 0px 10px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-floating-label {
    margin: 0px 10px !important;
    font-size: 18px !important;
  }
}

//buttons
.mdc-button{
    padding: 0 16px 0 16px !important;
}
