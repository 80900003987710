@import 'theme';
@import 'sidebar';
@import 'tables';
@import 'notifications';
@import 'forms';
@import 'bootstrap/scss/bootstrap-grid';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.main-container {
  margin-top: 100px;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

/*.cdk-overlay-connected-position-bounding-box {
  z-index: 99999 !important;
}*/

.modal-header {
  justify-content: space-between !important;
}

// below is for dashboard cards

.grid-cont {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
  margin-top: 55px;
}


@media only screen and (max-width: 1199px) {

  .grid-cont {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    text-align: center;
  }
}


.cardo {
  min-height: 165px;
  border: 1px solid #b5b5b5;
  border-radius: 3px;
  box-shadow: 0 5px 13px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  font: 500 20px Roboto;
}

.big-no {
  font-size: 60px;
  margin-bottom: 0;
  padding-bottom: 10px;
}


.small-white {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
  margin-top: -17px;
}

.small-red {
  font-size: 16px;
  color: #ff0000;
  line-height: 22px;
  justify-content: center;
  text-align: center;
}

.top {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  padding-top: 24px;
  min-height: 151px;
  align-items: center;
}

.green {
  background: #31797a; //#2b6a6b;// //#499092;
  color: #fff;
}

.bottoms {
  padding: 15px 15px 0 15px;
}

.bottom-text {
  font-size: 16px;
  line-height: 22px;
  justify-content: center;
  text-align: center;
}

.top.green.statistic {
  background: #e7f4f4 !important;
  color: #106062 !important;
}
